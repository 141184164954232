<template>
    <div class="row mb-2 justify-center font-20 font-medium">
        Lọc dữ liệu bán vé
    </div>

    <div class="content-popup pb-3">
        <DxValidationGroup ref="formValidation">
            <div class="row">Từ ngày (*)</div>
            <div class="row mb-4">
                <div class="xs12">
                    <DxDateBox
                        v-model="TuNgay"
                        displayFormat="dd/MM/yyyy"
                        :use-mask-behavior="true"
                        ref="NgayKhoiHanh"
                        dropDownButtonTemplate="customIcon"
                        validationMessageMode="always"
                        styling-mode="underlined"
                        :onValueChanged="LayDanhSachChuyenDiUyThac"
                    >
                        <DxValidator ref="validate">
                            <DxRequiredRule message="Vui lòng chọn ngày!" />
                        </DxValidator>
                        <template #customIcon="{}">
                            <DxButton icon="mdi mdi-calendar" />
                        </template>
                    </DxDateBox>
                </div>
            </div>

            <div class="row">Đến ngày (*)</div>
            <div class="row mb-4">
                <div class="xs12">
                    <DxDateBox
                        v-model="DenNgay"
                        displayFormat="dd/MM/yyyy"
                        :use-mask-behavior="true"
                        ref="NgayKhoiHanh"
                        dropDownButtonTemplate="customIcon"
                        validationMessageMode="always"
                        styling-mode="underlined"
                        :onValueChanged="LayDanhSachChuyenDiUyThac"
                    >
                        <DxValidator ref="validate">
                            <DxRequiredRule message="Vui lòng chọn ngày!" />
                        </DxValidator>
                        <template #customIcon="{}">
                            <DxButton icon="mdi mdi-calendar" />
                        </template>
                    </DxDateBox>
                </div>
            </div>

            <div class="row">Chuyến đi (*)</div>
            <div class="row">
                <div class="xs12">
                    <DxSelectBox
                        v-model="idChuyenDi"
                        :dataSource="{
                            store: DanhSachChuyenDi,
                        }"
                        ref="DanhSachChuyenDi"
                        :display-expr="
                            (x) =>
                                x && x.tuyenVanChuyen
                                    ? `${
                                          x.gioXuatBen
                                              ? $Helper.formatTime(
                                                    x.gioXuatBen
                                                ) + ' | '
                                              : ''
                                      } ${
                                          x.bienKiemSoat
                                              ? `${x.bienKiemSoat}` + ' | '
                                              : ''
                                      } ${x.tuyenVanChuyen} ${
                                          x.maTuyen ? `(${x.maTuyen})` : ''
                                      }`
                                    : ``
                        "
                        value-expr="idChuyenDi"
                        placeholder="Chọn chuyến đi"
                        validationMessageMode="always"
                        styling-mode="underlined"
                        :showDropDownButton="true"
                        :searchEnabled="true"
                        item-template="item"
                    >
                        <template #item="{ data }">
                            <div style="white-space: break-spaces">
                                {{
                                    data && data.tuyenVanChuyen
                                        ? `${
                                              data.gioXuatBen
                                                  ? $Helper.formatTime(
                                                        data.gioXuatBen
                                                    ) + " | "
                                                  : ""
                                          }${
                                              data.bienKiemSoat
                                                  ? `${data.bienKiemSoat}` +
                                                    " | "
                                                  : ""
                                          }${data.tuyenVanChuyen} ${
                                              data.maTuyen
                                                  ? `(${data.maTuyen})`
                                                  : ""
                                          }`
                                        : ``
                                }}
                            </div>
                        </template>
                        <DxValidator ref="validate">
                            <DxRequiredRule
                                message="Vui lòng chọn chuyến đi!"
                            />
                        </DxValidator>
                    </DxSelectBox>
                </div>
            </div>
        </DxValidationGroup>
    </div>

    <div class="row justify-space-between">
        <DxButton
            text="Hủy"
            type="default"
            styling-mode="text"
            @click="hiddenPopup"
        />
        <DxButton
            text="Tìm kiếm"
            type="default"
            styling-mode="contained"
            @click="LayDanhSachVe()"
        />
    </div>
</template>

<script>
import { DxRequiredRule } from "devextreme-vue/data-grid";
import {
    DxDateBox,
    DxSelectBox,
    DxValidationGroup,
    DxValidator,
    DxButton,
} from "devextreme-vue";

export default {
    components: {
        DxValidationGroup,
        DxDateBox,
        DxButton,
        DxSelectBox,
        DxValidator,
        DxRequiredRule,
    },
    data() {
        return {
            DanhSachChuyenDi: [],
            idChuyenDi: null,
        };
    },
    computed: {
        TuNgay: {
            get() {
                return this.$store.state.BaoCao.TuNgay;
            },
            set(data) {
                this.$store.commit("BaoCao/Set", {
                    key: "TuNgay",
                    data: data,
                });
            },
        },
        DenNgay: {
            get() {
                return this.$store.state.BaoCao.DenNgay;
            },
            set(data) {
                this.$store.commit("BaoCao/Set", {
                    key: "DenNgay",
                    data: data,
                });
            },
        },
        ChuyenDiDangChon: {
            get() {
                return this.$store.state.BaoCao.ChuyenDiDangChon;
            },
            set(data) {
                this.$store.commit("BaoCao/Set", {
                    key: "ChuyenDiDangChon",
                    data: data,
                });
            },
        },
    },
    watch: {
        idChuyenDi() {
            this.$store.commit("BaoCao/Set", {
                key: "ChuyenDiDangChon",
                data: this.idChuyenDi,
            });
        },
    },
    methods: {
        hiddenPopup() {
            this.$emit("hiddenPopup");
        },
        LayDanhSachVe() {
            let validate = this.$refs.formValidation.instance.validate();
            this.hiddenPopup();
            this.emitter.emit("LayDanhSachVe");
            if (validate.isValid) {
            } else {
                this.$Helper.autoFocusError(validate);
            }
        },
        async getData() {
            try {
                this.DanhSachChuyenDi = (
                    await this.$store.dispatch(
                        "BaoCao/Get_DanhSachChuyenDiDuocUyThac"
                    )
                ).Store;
            } catch (error) {
                console.log("🚀 ~ error", error);
            }
        },
        LayDanhSachChuyenDiUyThac(firstLoading) {
            setTimeout(() => {
                try {
                    let self = this;
                    let elmChuyenDi = self.$refs.DanhSachChuyenDi.instance;
                    elmChuyenDi
                        .getDataSource()
                        .load()
                        .done(function (items) {
                            if (firstLoading) {
                                self.idChuyenDi = self.ChuyenDiDangChon || 9999;
                            }
                        })
                        .catch(function (error) {
                            console.log("🚀 ~ error", error);
                        });
                } catch (error) {
                    console.log("🚀 ~ error", error);
                }
            }, 100);
        },
    },
    created() {
        this.getData();
    },
    mounted() {
        this.LayDanhSachChuyenDiUyThac(true);
    },
};
</script>

<style scoped>
</style>